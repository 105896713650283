import React from 'react';

const ServicesDevelopment = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1289 35.4394H7.07599C6.92092 35.4332 6.77413 35.3672 6.66665 35.2553C6.55917 35.1433 6.49939 34.9941 6.49951 34.8389V15.6221C6.49951 14.8003 6.82592 14.0121 7.40704 13.431C7.98817 12.8498 8.77649 12.5234 9.59832 12.5234H37.6307C38.4525 12.5234 39.2405 12.8498 39.8216 13.431C40.4028 14.0121 40.7295 14.8003 40.7295 15.6221V34.8389C40.7295 34.9982 40.6661 35.1509 40.5535 35.2635C40.4409 35.3761 40.2882 35.4394 40.1289 35.4394ZM7.67652 34.2384H39.4803V15.6702C39.4741 15.1688 39.2725 14.6897 38.9179 14.3351C38.5634 13.9806 38.0839 13.7787 37.5826 13.7724H9.55023C9.0447 13.7724 8.55933 13.9716 8.19963 14.3268C7.83993 14.682 7.63475 15.1647 7.62843 15.6702L7.67652 34.2384Z"
      fill="#475467"
    />
    <path
      d="M37.7267 33.0376H9.47797C9.3187 33.0376 9.166 32.9743 9.05338 32.8616C8.94076 32.749 8.87744 32.5963 8.87744 32.437V15.6223C8.88368 15.4354 8.9623 15.2582 9.09677 15.1283C9.23125 14.9983 9.41116 14.9257 9.59819 14.9258H37.6305C37.8153 14.9258 37.9925 14.9991 38.1231 15.1297C38.2538 15.2604 38.3272 15.4376 38.3272 15.6223V32.437C38.3272 32.5963 38.2639 32.749 38.1513 32.8616C38.0387 32.9743 37.886 33.0376 37.7267 33.0376ZM10.0785 31.8365H37.0781V16.1748H10.0304L10.0785 31.8365Z"
      fill="#475467"
    />
    <path
      d="M41.7381 40.3168H5.46646C5.17747 40.3133 4.89509 40.231 4.64953 40.0785C4.40397 39.9261 4.20469 39.7095 4.07334 39.4521C3.92511 39.2054 3.84668 38.9232 3.84668 38.6354C3.84668 38.3477 3.92511 38.0653 4.07334 37.8187L6.47545 34.4797C6.52848 34.4021 6.60045 34.3394 6.68451 34.2973C6.76858 34.2553 6.86185 34.2354 6.95575 34.2396H40.0087C40.1029 34.233 40.1975 34.2518 40.282 34.2941C40.3665 34.3363 40.4377 34.4004 40.489 34.4797L42.8911 37.8187C43.0686 38.0494 43.1747 38.3271 43.1961 38.6174C43.2174 38.9077 43.1531 39.1979 43.0113 39.4521C42.8938 39.6954 42.7141 39.9034 42.4906 40.0552C42.2671 40.2071 42.0076 40.2972 41.7381 40.3168ZM7.38826 35.5126L5.17822 38.5873C5.13536 38.6422 5.11224 38.7099 5.11224 38.7796C5.11224 38.8492 5.13536 38.9167 5.17822 38.9716C5.20242 39.0349 5.24535 39.0895 5.30108 39.1281C5.3568 39.1666 5.42273 39.1875 5.4905 39.1879H41.7622C41.834 39.1926 41.9057 39.1742 41.9663 39.1352C42.0268 39.0963 42.0733 39.0389 42.0988 38.9716C42.1351 38.9141 42.1539 38.8476 42.1539 38.7796C42.1539 38.7115 42.1351 38.6449 42.0988 38.5873L39.8644 35.5126H7.38826Z"
      fill="#475467"
    />
    <path
      d="M29.8719 40.3143H16.7805C16.6785 40.3152 16.578 40.2892 16.4894 40.2385C16.4007 40.1879 16.3269 40.1145 16.2759 40.0261C16.2232 39.9348 16.1956 39.8312 16.1956 39.7258C16.1956 39.6204 16.2232 39.5168 16.2759 39.4255L17.5731 37.0234C17.625 36.9287 17.7015 36.8497 17.7945 36.7947C17.8875 36.7398 17.9935 36.7109 18.1015 36.7112H28.5987C28.7072 36.7082 28.8143 36.736 28.9078 36.7912C29.0012 36.8465 29.0774 36.9269 29.1271 37.0234L30.4243 39.4255C30.4744 39.5171 30.5001 39.6203 30.4982 39.7246C30.4963 39.829 30.4671 39.9311 30.4138 40.0208C30.3604 40.1105 30.2846 40.1848 30.1938 40.2363C30.103 40.2878 30.0003 40.3146 29.8959 40.3143H29.8719ZM17.8614 39.1133H28.9828L28.3107 37.8643H18.534L17.8614 39.1133Z"
      fill="#475467"
    />
  </svg>
);

export default ServicesDevelopment;
